import React from "react";

import users from "../../assets/images/trainings/users.png";
import target from "../../assets/images/trainings/target.png";
import file from "../../assets/images/trainings/file.png";
import checklist from "../../assets/images/trainings/checklist.png";
import medal from "../../assets/images/trainings/medal.png";

const Slide3 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#46B7C5",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">الفئة المستهدفة</h4>
              <img
                style={{ width: "30px", marginLeft: "10px" }}
                src={target}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3" dir="rtl">
            الشباب والشابات الباحثين عن العمل من عمر 18 لغاية 30 عاما الحاصلين على الثانوية العامة أو البكالوريوس والراغبين في العمل في قطاع الترفيه أو الفعاليات بشتى أنواعها
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#46B7C5",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">نبذة عن البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={file}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
            البرنامج التأهيلي في قطاع الترفيه يؤهل حديثي التخرج والباحثين عن عمل من خلال بناء أساس أكاديمي معرفي وفرص تطبيقية في بيئة عمل فعلية ليساهم في توطين واستدامة القطاع ورفع الوعي حول الفرص الوظيفية المستجدة أيضا
فهم طبيعة المهن الحالية في القطاع لوضع معايير لتصنيف مهني قابل للتنفيذ خلال سنه من تنفيذ البرنامج

            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#46B7C5",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مزايا البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={medal}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>شهادة معتمدة من المؤسسة العامة للتدريب التقني و المهني</li>
                <li>مكافاة شهرية</li>
                <li>لغة انجليزية</li>
                <li>وظيفة بعد الانتهاء من البرنامج</li>
                <li>التسجيل في التأمينات الاجتماعية</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#46B7C5",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مدة البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={users}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">مدة البرنامج سنة واحدة ويلزم التفرغ تام</p>
          </div>
        </div>

        <div className="col-12 my-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#46B7C5",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">معايير القبول</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={checklist}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>سعودي الجنسية</li>
                <li>حديث التخرج</li>
                <li>ليس على رأس عمل عند بدء البرنامج</li>
                <li>متوافق مع متطلبات هدف</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
        <div className="row" style={{ 'width': 'max-content', 'padding-bottom': '20px' }}>
          <div className="col">
            <a href="https://takamol.disamina.in/candidates/live/login?examlink=4c59158a-d263-49e0-b5b5-0ee60292f397" target="_blank">
              <button className="text-white" style={{ 'backgroundColor': 'rgb(70, 183, 197)', 'padding': '10px 30px', 'border': 'none', 'color': 'white', 'fontSize': '18px' }}>
                سجل الان
              </button>

            </a>
          </div>
        </div>
    </div>
  );
};

export default Slide3;
