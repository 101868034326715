import React from "react";

import sideHalfSmiles from '../assets/images/objectives/side-half-smiles.png'
import rides from '../assets/images/objectives/rides.png'
import tent from '../assets/images/objectives/tent.png'
import fireworks from '../assets/images/objectives/fireworks.png'


const ObjectivesComponent = () => {
    return (

        <div className="objectives">
            <img className="side-half-smiles" src={sideHalfSmiles} alt="" />
            <h1 className="heading" style={{ 'fontFamily': 'thesans-plain, sans-serif', 'fontWeight': 'bold' }}>أهداف المبادرة</h1>

            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                        <div className="objective">
                            <img src={rides} alt="" />
                            <h5 >سد احتياج المعارف والمهارات والقدرات لأبناء وبنات الوطن لتمكينهم العمل في قطاع الترفيه</h5>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                        <div className="objective">
                            <img src={tent} alt="" />
                        <h5>دعم نمو المنشآت العاملة في القطاع</h5>
                        </div>

                    </div>
                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                        <div className="objective">
                            <img src={fireworks} alt="" />
                            <h5>تنفيذ مبادرة تطوير رأس المال البشري في قطاع الترفيه التابعة لبرنامج جودة الحياة</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ObjectivesComponent;