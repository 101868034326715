import React from "react";

import users from "../../assets/images/trainings/users.png";
import target from "../../assets/images/trainings/target.png";
import file from "../../assets/images/trainings/file.png";
import checklist from "../../assets/images/trainings/checklist.png";
import medal from "../../assets/images/trainings/medal.png";

const Slide5 = () => {
  return (
    <div className="container">
      <div className="row pb-3">
        <div className="col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#f5c46b",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">الفئة المستهدفة</h4>
              <img
                style={{ width: "30px", marginLeft: "10px" }}
                src={target}
                alt=""
              />
            </div>
          
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>موظفي الشركات المقدمة لخدمات تخص القطاع الترفيهي</li>
                <li>الأفراد الراغبين في بدء نشاط تجاري في الترفيه</li>
                <li>
                  المهتمين للالتحاق في فرص عمل تخص القطاع الترفيهي والمواسم
                </li>
                <li>
                  المهتمين للالتحاق في فرص عمل تخص القطاع الترفيهي والمواسم
                </li>
                <li>الراغبين في تقديم الدورات التدريبية في مجال الترفيه</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#f5c46b",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">نبذة عن البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={file}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              منصة تدريبية إلكترونية توفر دورات تدريبية بمحتوى يتعلّق بتطوير
              المهارات والمعارف والقدرات لسد احتياج التطوير في قطاع الترفيه.
              وتتميّز المنصة باستدامة محتواها ووصولها للمستفيدين في شتى أنحاء
              المملكة
            </p>
          </div>
        </div>

        <div className="col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#f5c46b",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">العدد المستهدف</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={users}
                alt=""
              />
            </div>
            <h3
              style={{ direction: "rtl" }}
              className="fw-bold text-end mb-0 mt-3"
            >
              100+ <span style={{ fontSize: "16px" }}>ألف</span>
            </h3>
            <p className="text-white fw-normal">مستفيد</p>
          </div>
        </div>
      </div>
      <div className="row" style={{ 'width': 'max-content', 'padding-bottom': '20px' }}>
          <div className="col">
            <a target="_blank" href="https://gea.futurex.sa/" className="text-white">
              <button className="text-white" style={{ 'backgroundColor': '#F5C46B', 'padding': '10px 30px', 'border': 'none', 'color': 'white', 'fontSize': '18px' }}>
                سجل الان
              </button>
            </a>
          </div>
        </div>

    </div>
  );
};

export default Slide5;

// #div
