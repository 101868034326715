import React, { useState, useRef, useEffect } from "react";
import centerSmile from "./../assets/images/landing/center-smile.png";

import leftArrow from "../assets/images/trainings/left-arrow.png";
import rightArrow from "../assets/images/trainings/right-arrow.png";

import Slide1 from "./new-slides/slide1.component";
import Slide2 from "./new-slides/slide2.component";
import Slide3 from "./new-slides/slide3.component";
import Slide4 from "./new-slides/slide4.component";
import Slide5 from "./new-slides/slide5.component";

import slide1Image from '../assets/images/trainings/slide-1.png'
import slide2Image from '../assets/images/trainings/slide-2.png'
import slide3Image from '../assets/images/trainings/slide-3.png'
import slide4Image from '../assets/images/trainings/slide-4.png'
import slide5Image from '../assets/images/trainings/slide-5.png'

const TrainingsComponent = () => {
  const [hoveredDiv, setHoveredDiv] = useState(null);

  return (
    <div className="trainings">
      <div className="container">
        <h1 className="fw-bold text-end mb-5">البرامج والمبادرات</h1>
      </div>

      <div className="container-fluid px-0">
        <div className="trainings-container">
          <div
            className={
              hoveredDiv === 0
                ? "training-wrapper hover-to-right slide5-wrapper"
                : "training-wrapper"
            }
            
          >
            <div className="training" 
            >
              <span className="heading orange-1"
               onClick={() => {
            
                if (hoveredDiv != null && hoveredDiv >= 0){
                  setHoveredDiv(null)
                }else{
                  setHoveredDiv(0);
                }
              }}>
                {
                  hoveredDiv == 0
                  ?
                  <img className="arrow" src={rightArrow} alt="" />
                  : 
                  <img className="arrow" src={leftArrow} alt="" />
                }
                <p>
                  المنصة
                  <br /> الالكترونية
                </p>
              </span>

              <div className={hoveredDiv === 0 ? `detail-1` : `detail-1 open-1`}>
                {hoveredDiv === 0 ? (
                  <Slide5 />
                ) : (
                ''
                )}
              </div>
            </div>
          </div>

          <div
            className={
              hoveredDiv === 1
                ? "training-wrapper hover-to-left slide4-wrapper"
                : "training-wrapper"
            }
            
          >
            <div className="training"
           >
              <span className="heading green-1"
               onClick={() => {
                if (hoveredDiv){
                  setHoveredDiv(null)
                }else{
                  setHoveredDiv(1);
                }
              }}>
              {
                  hoveredDiv == 1
                  ?
                  <img className="arrow" src={rightArrow} alt="" />
                  : 
                  <img className="arrow" src={leftArrow} alt="" />
                }
                <p>
                  برنامج الدورات <br />
                  التطويرية
                </p>
              </span>
              <div className={hoveredDiv === 1 ? `detail-2` : `detail-2 open-2`}>
                {hoveredDiv === 1 ? (
                  <Slide4 />
                ) : (
                 ''
                )}
              </div>
            </div>
          </div>

          <div
            className={
              hoveredDiv === 2
                ? "training-wrapper hover-to-left slide3-wrapper"
                : "training-wrapper"
            }
    
          >
            <div className="training"
            
            >
              <span className="heading blue-1"
              onClick={() => {
                if (hoveredDiv){
                  setHoveredDiv(null)
                }else{
                  setHoveredDiv(2);
                }
              }}>
              {
                  hoveredDiv == 2
                  ?
                  <img className="arrow" src={rightArrow} alt="" />
                  : 
                  <img className="arrow" src={leftArrow} alt="" />
                }
                <p>
                  البرنامج <br />
                  التأهيلي
                </p>
              </span>
              <div className={hoveredDiv === 2 ? `detail-3` : `detail-3 open-3`}>
                {hoveredDiv === 2 ? (
                  <Slide3 />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div
            className={
              hoveredDiv === 3
                ? "training-wrapper hover-to-left slide2-wrapper"
                : "training-wrapper"
            }
      
          >
            <div className="training">
              <span className="heading orange-3"
              onClick={() => {
                if (hoveredDiv){
                 setHoveredDiv(null)
               }else{
                 setHoveredDiv(3);
               }
             }}
              >
              {
                  hoveredDiv == 3
                  ?
                  <img className="arrow" src={rightArrow} alt="" />
                  : 
                  <img className="arrow" src={leftArrow} alt="" />
                }
                <p>
                  برنامج زمالة <br />
                  الترفيه
                </p>
              </span>
              <div className={hoveredDiv === 3 ? `detail-4` : `detail-4 open-4`}>
                {hoveredDiv === 3 ? (
                  <Slide2 />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div
            className={
              hoveredDiv === 4
                ? "training-wrapper hover-to-left slide1-wrapper"
                : "training-wrapper"
            }
            
          >
            <div className="training"
           >
              <span className="heading green-3" onClick={() => {
              if (hoveredDiv){
                setHoveredDiv(null)
              }else{
                setHoveredDiv(4);
              }
            }}>
              {
                  hoveredDiv == 4
                  ?
                  <img className="arrow" src={rightArrow} alt="" />
                  : 
                  <img className="arrow" src={leftArrow} alt="" />
                }
                <p>
                  برنامج قادة
                  <br /> الترفيه
                </p>
              </span>
              <div className={hoveredDiv === 4 ? `detail-5` : `detail-5 open-5`}>
                {hoveredDiv === 4 ? (
                  <Slide1 />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingsComponent;
