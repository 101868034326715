import React from "react";
import whiteHalfSmiles from '../assets/images/white-half-smiles.png'
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Input, Button, Spinner } from "reactstrap";

const ContactUs = () => {


    let IP;

    if (process.env.NODE_ENV == "production") {
        IP = "api.happiness-creators.com";
    } else {
        IP = "localhost:8000";
    }

    const sendData = async (payload) => {
        return await axios.post(
            `${window.location.protocol}//${IP}/api/send-email`,
            payload
        );
    };

    const sendFormMutation = useMutation(sendData, {
        onSuccess: (response) => {
            toast.success("تم ارسال رسالتك");
            resetForm();
        },
        onError: (response) => {
            toast.error("هناك خطأ ما. يرجى المحاولة لاحقا");
        },
    });

    const onSubmit = () => {
        sendFormMutation.mutate(values);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('الاسم مطلوب'),
        email: Yup.string().email('البريد الإلكتروني غير صالح').required('البريد الإلكتروني مطلوب'),
        message: Yup.string().required('الرسالة مطلوبة'),
    });

    const { values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm } = useFormik({
            initialValues: {
                name: "",
                email: "",
                message: "",
            },
            validationSchema: validationSchema,
            onSubmit,
            enableReinitialize: true,
        });


    return (

        <div className="contact-us-section">
            <div className="container">
                <img className="white-half-smiles" src={whiteHalfSmiles} alt="" />
                <h1 className="text-end fw-bold mb-5">
                    تواصل معنا
                </h1>

                <div className="row">
                    <div className="col-md-6 col-12"></div>
                    <div className="col-md-6 col-12">
                        <form className="text-end" onSubmit={handleSubmit}>

                            <label>الاسم</label>
                            <Input
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                dir="rtl"
                                className={
                                errors.name && touched.name
                                    ? "form-control input-field form-control-icon is-invalid"
                                    : "form-control input-field form-control-icon"
                                }
                                id="nameInput"
                                name="name"
                                disabled={sendFormMutation.isLoading}
                            />

                            <label htmlFor="">البريد الإلكتروني</label>
                           
                            <Input
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="email"
                                dir="rtl"
                                className={
                                errors.email && touched.email
                                    ? "form-control input-field form-control-icon is-invalid"
                                    : "form-control input-field form-control-icon"
                                }
                                id="emailInput"
                                name="email"
                                disabled={sendFormMutation.isLoading}
                            />

                            <label htmlFor="">الرسالة</label>
                            <Input
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="textarea"
                                dir="rtl"
                                className={
                                errors.message && touched.message
                                    ? "form-control input-field form-control-icon is-invalid"
                                    : "form-control input-field form-control-icon"
                                }
                                id="messageInput"
                                name="message"
                                disabled={sendFormMutation.isLoading}
                            />


                            <Button
                                disabled={sendFormMutation.isLoading}
                                className="contact-us-btn" type="submit">
                                    {
                                        sendFormMutation.isLoading ? 
                                        <>
                                         <Spinner size="sm" className="me-2" />
                                        <span>انتظر من فضلك</span>
                                        </>
                                        :
                                        <span>إرسال</span>
                                    }
                                    </Button>

                        </form>
                    </div>
                </div>

            </div>
        </div>


    )
}


export default ContactUs;