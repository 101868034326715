import React from "react";
import centerSmile from './../assets/images/landing/center-smile.png'
import newLogo from './../assets/images/logos/new-logo.png'
import geaLogo from './../assets/images/logos/gea-landing-logo.png'

const LandingComponent = () => {
    return (

        <div className="hero-section">
            <img className="center-smile" src={centerSmile} alt="" />
            <div className="landing-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 img-container">

                        </div>
                        <div className="col-6 logo-container">
                            <img className="landing-logo" src={newLogo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-bottom">
                <img src={geaLogo} alt="" />
            </div>
        </div>

    )
}


export default LandingComponent