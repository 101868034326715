import React, { useRef } from "react";

import users from "../../assets/images/trainings/users.png";
import target from "../../assets/images/trainings/target.png";
import file from "../../assets/images/trainings/file.png";
import checklist from "../../assets/images/trainings/checklist.png";
import medal from "../../assets/images/trainings/medal.png";


const Slide1 = () => {
  return (
    <div className="container">
      <div className="row">

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#67C669",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">الفئة المستهدفة</h4>
              <img
                style={{ width: "30px", marginLeft: "10px" }}
                src={target}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              أصحاب المناصب القيادية في قطاع الترفيه
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#67C669",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">نبذة عن البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={file}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3" dir="rtl">
         
              تهدف الهيئة العامة للترفيه إلى تمكين القطاع من خلال تقديم البرامج التدريبية والحلول التطويرية وزيارات ميدانية لوجهات عالمية مختلفة في مسارات قيادية وتخصصية مختلفة لتعزيز المهارات والكفاءات بطريقة مرنة وفعالة لتلبية الاحتياجات التدريبية والتطويرية اللازمة للقطاع وصولاً إلى الحصيلة المعرفية المطلوبة بما يحقق القدرة على استدامة العمل ورفع مساهمة القطاع الخاص ورفع مهاراتهم على أن يكونوا من القيادة والإدارة في قطاع الترفيه

            </p>


          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#67C669",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مزايا البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={medal}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>مكتبة إلكترونية شاملة متاحة طوال فترة البرنامج</li>
                <li>زيارات دولية مثرية لنقل تجارب ناجحة من قطاع الترفيه</li>
                <li>ورش عمل مع أفضل الجهات التدريبية</li>
                <li>تكاليف الزيارات الدولية مغطاة</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#67C669",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مدة البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={users}
                alt=""
              />
            </div>
              <p className="text-white fw-normal py-3" dir="rtl">مدة البرنامج 5 أشهر، لا يتطلب التفرغ التام ويلزم حضور عدد 3 ورش عمل والمشاركة في الزيارات الميدانية خارج المملكة لمدة 10 أيام</p>
          </div>
        </div>

        <div className="col-12 my-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#67C669",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">معايير القبول</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={checklist}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>سعودي الجنسية</li>
                <li>قادة الشركات الترفيهية العاملة في القطاع الترفيهي</li>
                <li>
                  لم يسبق للمتدرب الاستفادة أو الانسحاب من برنامج مماثل مع
                  الهيئة
                </li>
                <li>تقديم ما يثبت تمكّنه من اللغة الإنجليزية</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="row" style={{ 'width': 'max-content', 'padding-bottom': '20px' }}>
        <div className="col">
            <a target="_blank"  href="https://takamol.disamina.in/candidates/live/login?examlink=58543150-f89b-4f55-a0bb-916cb0f42c91">
          <button className="text-white" style={{ 'backgroundColor': 'rgb(103, 198, 105)', 'padding': '10px 30px', 'border': 'none', 'fontSize': '18px' }}>
          سجل الان
          </button>
            </a>
        </div>
      </div>
    </div>
  );
};

export default Slide1;
