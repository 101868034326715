import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img1 from '../../assets/images/visits/1.png'
import img2 from '../../assets/images/visits/2.png'
import img3 from '../../assets/images/visits/3.png'
import img4 from '../../assets/images/visits/4.png'
import img5 from '../../assets/images/visits/5.png'
import img6 from '../../assets/images/visits/6.png'


import img7 from '../../assets/images/visits/1.jpg'
import img8 from '../../assets/images/visits/2.jpg'
import img9 from '../../assets/images/visits/3.jpg'
import img10 from '../../assets/images/visits/4.jpg'
import img11 from '../../assets/images/visits/5.jpg'
import img12 from '../../assets/images/visits/6.jpg'
import img13 from '../../assets/images/visits/7.jpg'
import img14 from '../../assets/images/visits/8.jpg'
import img15 from '../../assets/images/visits/9.jpeg'
import img16 from '../../assets/images/visits/10.jpg'
import img17 from '../../assets/images/visits/11.jpg'
import img18 from '../../assets/images/visits/12.jpg'
import img19 from '../../assets/images/visits/13.jpg'
import img20 from '../../assets/images/visits/14.jpg'


import img21Thumbnail from '../../assets/images/visits/15-thumbnail.jpg'
import img21 from '../../assets/images/visits/15.jpg'
import img22 from '../../assets/images/visits/16.jpg'
import img23 from '../../assets/images/visits/17.jpg'
import img24 from '../../assets/images/visits/18.jpg'



const FieldVisits = () => {
    

    return (
        <div className="container">
            <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginTop: '50px' }}>
            الزيارات الميدانية
            </h1>

            <LightGallery
                speed={500}
                plugins={[lgZoom]}
                mobileSettings={{ download: true }}>

                    <a href={img21}>
                        <LazyLoadImage src={img21Thumbnail} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a href={img22}>
                        <LazyLoadImage src={img22} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a href={img23}>
                        <LazyLoadImage src={img23} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a href={img24}>
                        <LazyLoadImage src={img24} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a href={img7}>
                        <LazyLoadImage src={img7} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a href={img8}>
                        <LazyLoadImage src={img8} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a href={img9}>
                        <LazyLoadImage src={img9} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a href={img10}>
                        <LazyLoadImage src={img10} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a href={img11}>
                        <LazyLoadImage src={img11} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a href={img12}>
                        <LazyLoadImage src={img12} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img13}>
                        <LazyLoadImage src={img13} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img14}>
                        <LazyLoadImage src={img14} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img15}>
                        <LazyLoadImage src={img15} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    
                    <a  href={img16}>
                        <LazyLoadImage src={img16} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img17}>
                        <LazyLoadImage src={img17} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img18}>
                        <LazyLoadImage src={img18} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img19}>
                        <LazyLoadImage src={img19} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img20}>
                        <LazyLoadImage src={img20} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>


                    {/* old images */}
                    <a  href={img1}>
                        <LazyLoadImage src={img1} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img2}>
                        <LazyLoadImage src={img2} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a  href={img3}>
                        <LazyLoadImage src={img3} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a  href={img4}>
                        <LazyLoadImage src={img4} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a  href={img5}>
                        <LazyLoadImage src={img5} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a  href={img6}>
                        <LazyLoadImage src={img6} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
            </LightGallery>

        </div>
    );
};

export default FieldVisits;
