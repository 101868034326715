import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img1 from '../../assets/images/leadership/leadership-1.jpg'
import img2 from '../../assets/images/leadership/leadership-2.jpg'
import img3 from '../../assets/images/leadership/leadership-3.jpg'
import img4 from '../../assets/images/leadership/leadership-4.jpg'
import img5 from '../../assets/images/leadership/leadership-5.jpg'
import img6 from '../../assets/images/leadership/leadership-6.jpg'
import img7 from '../../assets/images/leadership/7.jpg'
import img8 from '../../assets/images/leadership/8.jpg'
import img9 from '../../assets/images/leadership/9.jpg'


import img10 from '../../assets/images/fellowship/3.jpg'
import img11 from '../../assets/images/fellowship/4.png'
import img12 from '../../assets/images/fellowship/5.jpg'
import img13 from '../../assets/images/fellowship/6.png'
import img14 from '../../assets/images/fellowship/7.jpg'
import img15 from '../../assets/images/fellowship/8.jpg'

import img16 from '../../assets/images/fellowship/9.jpg'
import img17 from '../../assets/images/fellowship/10.jpg'
import img18 from '../../assets/images/fellowship/11.jpg'
import img19 from '../../assets/images/fellowship/12.jpg'
import img20 from '../../assets/images/fellowship/13.jpg'
import img21 from '../../assets/images/fellowship/14.jpg'




const Workshops = () => {
    

  
    return (
        <div className="container">
            <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginTop: '50px' }}>
            ورش العمل
            </h1>

            <LightGallery
                speed={500}
                plugins={[lgZoom]}
                mobileSettings={{ download: true }}>
                    
                    <a  href={img16}>
                        <LazyLoadImage src={img16} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img17}>
                        <LazyLoadImage src={img17} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img18}>
                        <LazyLoadImage src={img18} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img19}>
                        <LazyLoadImage src={img19} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img20}>
                        <LazyLoadImage src={img20} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img21}>
                        <LazyLoadImage src={img21} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>



                {/* Old pictures */}


                    <a  href={img7}>
                        <LazyLoadImage src={img7} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    
                    <a  href={img2}>
                        <LazyLoadImage src={img2} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img8}>
                        <LazyLoadImage src={img8} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img9}>
                        <LazyLoadImage src={img9} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img5}>
                        <LazyLoadImage src={img5} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img6}>
                        <LazyLoadImage src={img6} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                  

                    <a  href={img10}>
                        <LazyLoadImage src={img10} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img11}>
                        <LazyLoadImage src={img11} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    {/* <a  href={img12}>
                        <LazyLoadImage src={img12} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a> */}
                    <a  href={img13}>
                        <LazyLoadImage src={img13} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img14}>
                        <LazyLoadImage src={img14} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img15}>
                        <LazyLoadImage src={img15} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                  
            </LightGallery>

        </div>
    );
};

export default Workshops;
