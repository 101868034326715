import React, { useState } from 'react';


const HoverEffect = () => {
  // State to track the hovered div
  const [hoveredDiv, setHoveredDiv] = useState(null);


  return (
    <div className="trainings-container">

    </div>
  );
};

export default HoverEffect;
