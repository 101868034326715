import React from "react";

import initiativeImge from '../assets/images/initiative/main2.png'
import infographic from '../assets/images/initiative/icon.png'

import handMic from '../assets/images/initiative/hand-mic.png'

const InitiativeComponent = () => {
    return (

        <div className="initiative">
            <div className="container-fluid ps-0 mt-5 pt-2">
                <div className="row">
                    <div className="col-12 col-md-6 ps-0 text-left">
                        <img className="initiative-img" src={handMic} alt="" />
                    </div>

                    <div className="d-none d-lg-block col-lg-1 img-div"></div>

                    <div className="col-12 col-md-5 text-end text-div">
                        <p className="my-1" style={{ 'fontFamily': 'thesans-plain, sans-serif' }}>عن المبادرة</p>
                        <h1 style={{ 'fontFamily': 'thesans-plain, sans-serif' }}>هي مبادرة أطلقتها الهيئة العامة للترفيه عام 2021م</h1>
                        <h4 className="mt-4 mb-5" style={{ 'fontFamily': 'thesans-plain, sans-serif', fontWeight: 'normal' }}>
                        
                        مبادرة متخصصة في تأهيل وتدريب وتطوير الكوادر البشرية في القطاع الترفيهي الذي يعد أحد أهم القطاعات في المملكة وأحدثها، وتحتوي على 5 برامج تدريبية وتأهيلية وتطويرية مختلفة لتعزيز صناعة القطاع الترفيهي ودعم أكثر من 100 ألف شاب وشابة</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default InitiativeComponent