import React from "react";

import circleGraphic from '../assets/images/circle-graphic-1.png'


import bottomRight from '../assets/images/bottom-right.png';
import bottomLeft from '../assets/images/bottom-left.png';
import topRight from '../assets/images/top-right.png';
import topLeft from '../assets/images/top-left.png';

const Questions = () => {
    return (
       <div className="container questions" style={{'position': 'relative'}} id='questions-component'>
            <div className="row">
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                    أين سيقام البرنامج؟
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                جميع البرامج سوف تقام داخل المملكة. بعض البرامج تتضمن زيارات خارج المملكة لجهات دولية رائدة في قطاع الترفيه 
                </p>
                

                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                كم مدة البرنامج؟ 
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                مدة البرنامج خمسة أشهر ويجمع مابين الجلسات التدريبية أونلاين والحضورية والزيارات الميدانية العملية الخارجية 
                </p>
               
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                ماهي جهة اعتماد الشهادة
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                شهادة حضور من الهيئة العامة للترفيه وشهادة من الجامعة الدولية المقدمة للبرنامج
                </p>
               
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                هل يلزم على المقبولين استخراج تأشيرة السفر للزيارات الدولية؟
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                نعم
                </p>
               
               
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                هل تأشيرة السفر شرط لاستمرار المقبولين في البرنامج؟
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                نعم. بالإضافة الى سريان مفعول جواز السفر وتجديده في حال الانتهاء قبل البدء بالبرنامج
                </p>
               
               
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                ماهي الية معرفة قبولي في البرنامج؟
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                في حال القبول سوف يتم التواصل معك من خلال فريق البرنامج عبر البريد الإلكتروني 
                </p>
               
               
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="question">
                هل يتطلب التفرغ التام للإلحاق بالبرنامج؟
                </p>
                
                <p style={{'fontFamily': 'thesans-plain, sans-serif'}} className="answer">
                لا يتطلب تفرغ تام. ولكن يجب موافقة جهة العمل والالتزام بحضور عدد محدد من ورش العمل وأيضاَ حضور الزيارات الميدانية خارج السعودية
                </p>
               
                
                </div>
            </div>

    )
}


export default Questions;
