import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated, config } from "react-spring";
import PreviousWork from "./previous-work.component";
import Questions from "./questions.component";


const GalleryQuestions = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [selected, setSelected] = useState(1);
  const [slideHeights, setSlideHeights] = useState([null, null, null])

  const [hideGalleryGraphics, setHideGalleryGraphics] = useState(true)
  const [hideQuestionsGraphics, setHideQuestionsGraphics] = useState(false)

  const sliderProps = useSpring({
    transform: `translateX(-${(selected - 1) * 100}%)`,
    height: slideHeights[selected - 1], // Use the specified height or null
    config: config.gentle,
  });

  const questionsRef = useRef(null);
  const galleryRef = useRef(null);


  const updateHeights = () => {

    if (selected === 1 && questionsRef.current) {
      const questionsHeight = questionsRef.current.clientHeight;
      setHideQuestionsGraphics(false)
      setHideGalleryGraphics(true)
      setSlideHeights([null, questionsHeight, null])
    }
    
    if (selected === 2 && galleryRef.current){
      const galleryHeight = galleryRef.current.clientHeight;
      setHideGalleryGraphics(false)
      setHideQuestionsGraphics(true)
      setSlideHeights([galleryHeight, null, null])
    }
    
  }
  
  const updateHeightsOnResize = () => {

    if (questionsRef.current) {
      const questionsHeight = questionsRef.current.clientHeight;
      const galleryHeight = galleryRef.current.clientHeight;
      setSlideHeights([galleryHeight, questionsHeight, null])
    }

  }

  useEffect(() => {
    window.addEventListener('resize', updateHeightsOnResize)
      updateHeights()
  }, [selected]);



  const handleButtonClick = (buttonNumber) => {
    setSelected(buttonNumber);
    setActiveIndex(buttonNumber);
  };

  return (
    <>
      <div className="gallery-questions">
        <div className="container-fluid px-0">

          <div className="container pt-5 text-end">

              <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginBottom: '50px' }}
              >
                أعمال سابقة
              </h1>
            <PreviousWork/>
              
              <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginTop: '50px'}}
              >
                الأسئلة الشائعة
              </h1>
                <Questions/>
              </div>
        </div>
      </div>
    </>
  );
};

export default GalleryQuestions;
