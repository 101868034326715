import React from "react";
import { Link } from "react-router-dom";

import fellowship from '../assets/images/previous-work/fellowship.jpg'
import leadership from '../assets/images/previous-work/leadership.jpg'
import openingCeremony from '../assets/images/opening-ceremony/9.jpg'

import fieldVisits from '../assets/images/visits/17.jpg'

const PreviousWork = () => {
    return (
        <div className="container-fluid previous-work">
            <div className="row">
            <div className="col-md-4 col-12 mb-md-0 mb-2">
              <Link to='opening-ceremony'>
                    <div className="img-wrapper">
                        <img src={openingCeremony} alt="" />
                        <div className="title">التكريم</div>

                    </div>
                    </Link>
                </div>               

                <div className="col-md-4 col-12 mb-md-0 mb-2">
              <Link to='visits'>
                    <div className="img-wrapper">
                        <img src={fieldVisits} alt="" />
                        <div className="title">الزيارات الميدانية</div>
                    </div>
                    </Link>

                </div>

                <div className="col-md-4 col-12 mb-md-0 mb-2">
                <Link to='workshops'>
                    <div className="img-wrapper">
                        <img src={leadership} alt="" />
                        <div className="title">ورش العمل </div>
                    </div>
                </Link>
                </div>
            </div>
        </div>
    )
}


export default PreviousWork; 